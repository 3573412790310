const base_url = 'http://localhost';

const Config = {
  site_name: '패션 바이 카카오',
  baseDomain: '',
  apiBaseUrl: `${base_url}:3061/api/store`,
  apiConsumerBaseUrl: `${base_url}:3061/api/2`,
  appUrl: `${base_url}:3061`,
  contentUrl: 'https://content.alpha.zigzag.kr',
  assetPrefix: '',
  payApiBaseUrl: `${base_url}:9132/api/user-pay`,
  simplePayPassword: `${base_url}:9141/api/security-keypad/static/js/simple_pay/simple_pay_password_local.js`,
  simpleBankAppVersion: '6.5.0',
  reviewAppIOSVersion: '6.12.0',
  reviewAppAndroidVersion: '6.14.1',
  inlineImageLimit: Number.POSITIVE_INFINITY,
  localIdentName: '[path][name]__[local]--[hash:base64:5]',
  port: 3061,
  sentry_dsn: '',
  sentry_org: '',
  kakaoRestApiKey: 'd6d61d372970fe2448ed3354cf1e6110',
  kakaoSandboxRestApiKey: '3811c64be198b28952291e750f78592a',
  kakaoJavascriptKey: 'd6d61d372970fe2448ed3354cf1e6110',
  live_player_access_key: 'EFro7KRE5UldHywwJsE6',
  resUrl: 'https://cf.res.s.zigzag.kr',
  aasaDestination: '/.well-known/apple-app-site-association.alpha',
  s3_cf_url: 'https://cf.ad-display.s.zigzag.kr/fbk',
  ga_id: '',
  fbq_id: '',
  app_scheme: 'fbk-alpha',
  // 카카오톡 메세지 친구초대 템플릿 템플릿 id
  kakao_invite_friend_template_id: 75915,
  // 친구초대 쿠폰 id
  invite_code_welcome_coupon_id: '13169',
  // 카카오톡 메세지 공유왕 템플릿 id
  kakao_share_king_template_id: 83558,
  // 앱 다이나믹 딥링크
  dynamic_deep_link: 'https://fashionbykakao.page.link',
  // IOS APP 설치 id
  ios_app_store_id: '653403166',
  // 파이어베이스 키값
  firebase_web_key: 'AIzaSyAp5QzeMiJP1ay9bxTsC83uf4HppuTNK2k',
  zendesk_url: 'https://fashionbykakao.zendesk.com/hc/p/requests-with-sso',
  // 회원가입 웰컴 쿠폰 id
  register_complete_welcome_coupon_id: '8a3736c7-4bf6-4f85-bc43-2addeff8482b',
  // 랜덤혜택 페이지 uuid
  random_benefit_content_uuid: 'ed6bb048-76d0-4bf3-a1be-264e6283277f',
  // 랜덤혜택 메타데이터 uuid
  random_benefit_metadata_uuid: '4d5f30b9-587c-49db-99a2-a6f663ee5b40',
  // 키즈 카테고리 id
  kid_category_id: '3040',
  // '전체' 상품 카테고리 id
  root_product_category_id: '2071',
  // 에어브릿지 앱 이름
  airbridge_app_name: 'fbkalpha',
  // 에어브릿지 웹 SDK 토큰
  airbridge_web_token: '1dfab839bad24fc19e262dc8e4b8037c',
  // 에어브릿지 url
  airbridge_url: 'https://abr.ge/@fbkalpha',
  // 에어브릿지 공유 채널
  airbridge_share_channel: 'in_app_share',
};

// from https://github.com/krzkaczor/ts-essentials
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : // tslint:disable-next-line:no-shadowed-variable
    T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export type BaseConfigType = DeepPartial<typeof Config>;

export default Config;
